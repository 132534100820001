import FooterLogo from "../../images/logo/logo-footer.svg";

function Footer() {
  return (
    <>
      <footer className="bg-white">
        <div className="container page-padding py-[10rem]">
          {/* footer div all */}
          <div className="flex justify-between min620:flex-col min620:items-center min620:text-center min620:gap-[5rem] !text-left">
            {/* logo side */}
            <div className="flex flex-col w-1/3 gap-8">
              <img src={FooterLogo} alt="footer_logo" className="w-[18rem]" />
              <p className="text-[15px] font-medium text-[#646464]">
                Take your health and body to the next level with our
                comprehensive program designed to help you reach your fitness
                goals.
              </p>
              <p className="text-[16px] font-medium text-[#646464]">
                Privacy Policy | © {new Date().getFullYear()} FitCurse
              </p>
              {/* socials */}
              <div className="flex gap-7 text-[18px] text-[#646464] min540:justify-center">
                <a href="https://www.facebook.com/people/Fit-Curse/pfbid0RJdpLvWEcuAe8wfLUL7UBfcDEKKBvxGMT8RdtGJYyiakrwUov8gNTQfCbykH7wesl/?mibextid=qi2Omg&rdid=vx3t33jkj2u6vtiU&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FfVKymYZMyb73Ypjb%2F%3Fmibextid%3Dqi2Omg" target="_blank" rel="noopener noreferrer">
                  <i className="fa-brands fa-facebook-f px-[16px] py-[13px] bg-[#f4f4f4] rounded-full cursor-pointer hover:bg-[#ff0336] ease-in duration-200 hover:text-white"></i>
                </a>
                <a href="https://www.instagram.com/fitcurseofficial" target="_blank" rel="noopener noreferrer">
                  <i className="fa-brands fa-instagram p-[13.5px] bg-[#f4f4f4] rounded-full cursor-pointer hover:bg-[#ff0336] ease-in duration-200 hover:text-white"></i>
                </a>
              </div>
            </div>

            {/* middle div */}
            <div className="flex flex-col gap-8 relative"/>
            
            {/* right div */}
            <div className="flex flex-col gap-8 relative">
              <p className="text-[22px] font-bold footer-main">Our Classes</p>

              <span className="top-[33px] absolute w-[7rem] h-[4px] bg-[#ff0366]"></span>

              <p className="text-[16px] hover:text-[#ff0366] cursor-pointer text-[#646464] font-medium">
                Body Building
              </p>

              <p className="text-[16px] hover:text-[#ff0366] cursor-pointer text-[#646464] font-medium">
                Fitness
              </p>

              <p className="text-[16px] hover:text-[#ff0366] cursor-pointer text-[#646464] font-medium">
                Workout
              </p>
            </div>

            {/* middle div */}
            <span></span>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
