import { useState } from "react";
import ClassesBox from "../components/ClassesBox/ClassesBox";
import Footer from "../components/Footer/Footer";

function Classes() {
  const [load, setLoad] = useState(true);

  const loadMore = () => {
    setLoad(!load);
  };

  const goTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <section className="login-section text-center ">
        <div className="login-banner relative justify-center flex">
          <h1 className="text-white absolute bottom-[25px] text-[3rem] font-bold">
            Classes
          </h1>
        </div>

        {/* boxes 1*/}
        <div
          className={`container gap-12 page-padding py-[10rem] md1000:grid-cols-2 min620:grid-cols-1  grid-cols-3 grid-rows-1 text-left ${
            load ? "grid" : "hidden"
          }`}
        >
          <ClassesBox
            bgImg="powerlift-bg"
            title="Body Building"
            trainer="Arun Raj"
          />
          <ClassesBox
            bgImg="workout-bg"
            title="Workout"
            trainer="Arun Raj"
          />
          <ClassesBox
            bgImg="running-bg"
            title="Fitness"
            trainer="Arun Raj"
          />
        </div>
        <Footer />
      </section>
    </>
  );
}

export default Classes;
