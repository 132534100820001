import Footer from "../components/Footer/Footer";

function Login() {
  return (
    <>
      <section>
        <div className="login-banner relative justify-center flex">
          <h1 className="text-white absolute bottom-[25px] text-[3rem] font-bold">
            Contact Us
          </h1>
        </div>
        {/* contact */}
        <div className="bg-white py-[12rem] page-padding grid grid-cols-1 container gap-10 items-center md1000:grid-cols-1 ">
          {/* first col */}
          <div className="md1000:w-[60%] md1000:flex md1000:flex-col md1000:mx-auto min800:w-[90%] min620:w-full">
            <h2 className="text-black text-[36px] font-bold leading-[1.2]">
              We are here to help you!<br/>To Shape Your Body.
            </h2>
            <p className="text-[16px] w-[95%] text-[#646464] font-medium my-[40px]">
              At FitCurse, we are dedicated to help you achieve the body of
              your dreams. Our expert trainers and nutritionists will work with
              you to create a personalized fitness and nutrition plan that helps
              you reach your specific goals.
            </p>
            <div className="grid grid-cols-2 grid-rows-2 gap-y-20 min620:grid-cols-1 min620:mx-auto">
              <div className="flex flex-col relative">
                <h3 className="text-[2rem] font-bold mb-10">
                Karnataka, India
                </h3>
                <span className="bg-[#ff0336] w-[50px] h-[4px] absolute top-[33px]"></span>
                <p className="text-[15px] font-medium text-[#646464]">
                  5th Cross Nallurhalli,
                  <br /> Whitefield, Bangalore - 560066
                </p>
              </div>
              {/*  */}
              <div className="flex flex-col relative">
                <h3 className="text-[2rem] font-bold mb-10">Information</h3>
                <span className="bg-[#ff0336] w-[50px] h-[4px] absolute top-[33px]"></span>
                <div className="text-[15px] font-medium text-[#646464]">
                  <p>+91 843-160-1843</p>
                  <p>fitcurse@gmail.com</p>
                </div>
              </div>
              {/*  */}
              <div className="flex flex-col relative">
                <h3 className="text-[2rem] font-bold mb-10">Follow Us On</h3>
                <span className="bg-[#ff0336] w-[50px] h-[4px] absolute top-[33px]"></span>
                <div className="text-[18px] font-medium text-black flex gap-5">
                  <a href="https://www.facebook.com/people/Fit-Curse/pfbid0RJdpLvWEcuAe8wfLUL7UBfcDEKKBvxGMT8RdtGJYyiakrwUov8gNTQfCbykH7wesl/?mibextid=qi2Omg&rdid=vx3t33jkj2u6vtiU&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FfVKymYZMyb73Ypjb%2F%3Fmibextid%3Dqi2Omg" target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-facebook-f px-[16px] py-[13px] bg-[#f4f4f4] rounded-full cursor-pointer hover:bg-[#ff0336] ease-in duration-200 hover:text-white"></i>
                  </a>
                  <a href="https://www.instagram.com/fitcurseofficial" target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-instagram p-[13.5px] bg-[#f4f4f4] rounded-full cursor-pointer hover:bg-[#ff0336] ease-in duration-200 hover:text-white"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* map */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.344863011186!2d77.74290331482191!3d12.987380390857357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14e3c5bdc0e3%3A0x7a7c0a3e5f5d8b0e!2s5th%20Cross%20Nallurhalli%2C%20Whitefield%2C%20Bengaluru%2C%20Karnataka%20560066%2C%20India!5e0!3m2!1sen!2sus!4v1673981026796!5m2!1sen!2sus"
          allowFullScreen=""
          loading="lazy"
          samesite="Strict"
          title="map"
          style={{ width: "100%", height: "45rem", border: "0px" }}
        ></iframe>
        <Footer />
      </section>
    </>
  );
}

export default Login;
